.dd-lang {
    display: block;
    width: 100%;
    padding: 0.75rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    }
    
    /*@keyframes blinking {
        0% {
            background-color: #666666;
        }
    
        100% {
            background-color: #2198F3;
        }
    }
    
    
    .fa-bell {
        animation: blinking 1s infinite;
    }*/
    
    .hover-white:hover {
        background: #e7e7e7;
        text-emphasis-color: #785BA5;
        text-decoration-color: #785BA5;
        color: #785BA5;
    }
    
    