.modal-dialog-full-width {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    max-width:none !important;

}

.modal-content-full-width  {
    height: auto !important;
    min-height: 100% !important;
    border-radius: 0 !important;
    background-color: #ececec !important 
}

.modal-header-full-width  {
    border-bottom: 1px solid #9ea2a2 !important;
}

.modal-footer-full-width  {
    border-top: 1px solid #9ea2a2 !important;
}

.modal-fullscr{
    width: 0vw;
    max-width: none;
    /* height: 92.5%; */
    height: calc(100% - 70px);
    margin: 0;
    padding: 0;
}

.modal-fullscr .modal-content{
    /* width: 95.7vw; */
    width: calc(100vw - 77px);
    max-width: none;
    height: 100%;
    margin: 0;

    padding: 0;
    left: 77px;
    top: 70px;
}

